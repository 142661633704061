import React, { Fragment } from 'react';
import format from 'date-fns/format';
import isDate from 'date-fns/isDate';
import differenceInDays from 'date-fns/differenceInDays';
import { Box, HStack, Text } from '@chakra-ui/react';
import NewBadge from 'timelesstime-ui/components/new-badge';

import TypeBadge from '../type-badge';

const KnowledgeMetaHeader = ({ knowledgebaseArticle }) => {
  const updatedAtDateAsDate = new Date(knowledgebaseArticle.updatedAt);
  const createdAtDateAsDate = new Date(knowledgebaseArticle.createdAt);
  const writtenAtDateAsDate = new Date(knowledgebaseArticle.fields.writtenAt);
  return (
    <Box>
      <HStack spacing={1}>
        <TypeBadge
          type={knowledgebaseArticle.knowledgeType}
          title={knowledgebaseArticle.knowledgeType}
        />
        <NewBadge
          isNew={
            knowledgebaseArticle.fields.isNew ||
            knowledgebaseArticle.fields.isRecent
          }
        />
        <Text color="gray.500" fontSize="sm">
          Written{' '}
          {knowledgebaseArticle.authors.length > 0 && (
            <>
              by{' '}
              {knowledgebaseArticle.authors.map((author, index) => (
                <Fragment key={author.id}>
                  {index > 0 && '&'} {author.firstName} {author.lastName}{' '}
                </Fragment>
              ))}
            </>
          )}{' '}
          on {format(writtenAtDateAsDate, 'do MMMM yyyy')}.
          {isDate(updatedAtDateAsDate) &&
            differenceInDays(updatedAtDateAsDate, createdAtDateAsDate) && (
              <> Revised {format(updatedAtDateAsDate, 'do MMMM yyyy')}.</>
            )}
        </Text>
        <Text color="gray.500" fontSize="sm">
          {knowledgebaseArticle.fields.readingTimeText && (
            <>{knowledgebaseArticle.fields.readingTimeText}</>
          )}
        </Text>
      </HStack>
    </Box>
  );
};

export default KnowledgeMetaHeader;
