import React from 'react';
import { graphql } from 'gatsby';
import format from 'date-fns/format';
import {
  Container,
  Box,
  Divider,
  HStack,
  Text,
  SimpleGrid,
  Flex,
} from '@chakra-ui/react';

import CanonicalLink from 'timelesstime-ui/components/canonical-link';
import SEO from 'timelesstime-ui/components/seo';
import { KnowledgebaseLeadInHeader } from 'timelesstime-ui/components/lead-in-header';
import Html from 'timelesstime-ui/components/html';
import NewsletterSignup from 'timelesstime-ui/components/newsletter-signup';
import Heading from 'timelesstime-ui/components/heading';
import Jumbotron from 'timelesstime-ui/components/jumbotron';
import PageLayout from '../components/layout';
import KnowledgebaseRelatedPageCard from '../components/Knowledgebase-related-page-card';
import KnowledgeMetaHeader from '../components/knowledge-meta-header';
import KnowledgeStructuredData from '../components/structured-data/knowledge';

const KnowledgebasePage = ({ data: { contentfulKnowledge } }) => (
  <PageLayout
    leadInHeader={
      <KnowledgebaseLeadInHeader
        heading={contentfulKnowledge.heading || contentfulKnowledge.title}
        crumbs={[
          {
            url: '/knowledgebase/',
            title: 'Knowledgebase',
          },
          {
            url: `/knowledgebase/${contentfulKnowledge.slug}/`,
            title: contentfulKnowledge.title,
          },
        ]}
      />
    }
  >
    <SEO
      title={contentfulKnowledge.title}
      keywords={contentfulKnowledge.fields.keywords}
      description={contentfulKnowledge.metaDescription}
      canonicalPath={contentfulKnowledge.fields.path}
      thumbnail={contentfulKnowledge.jsonLdThumbnailImage}
      ogType="Article"
      meta={[
        {
          name: 'article:published_time',
          content: format(
            new Date(contentfulKnowledge.fields.writtenAt),
            'yyyy-MM-dd[T]HH:mm:ssxx',
          ),
        },
        {
          name: 'article:modified_time',
          content: format(
            new Date(contentfulKnowledge.updatedAt),
            'yyyy-MM-dd[T]HH:mm:ssxx',
          ),
        },
        {
          name: 'article:section',
          content: contentfulKnowledge.knowledgeType,
        },
        {
          name: 'article:author',
          content: 'https://timelesstime.co.uk/',
        },
        ...(contentfulKnowledge.fields.keywords
          ? contentfulKnowledge.fields.keywords.map((keyword) => ({
              name: 'article:tag',
              content: keyword,
            }))
          : []),
      ]}
    />

    <KnowledgeStructuredData
      slug={contentfulKnowledge.slug}
      knowledgeType={contentfulKnowledge.knowledgeType}
      description={contentfulKnowledge.metaDescription}
      title={contentfulKnowledge.title}
      createdAt={new Date(contentfulKnowledge.createdAt)}
      writtenAt={new Date(contentfulKnowledge.fields.writtenAt)}
      updatedAt={new Date(contentfulKnowledge.updatedAt)}
      plaintextContent={contentfulKnowledge.fields.plaintextContent}
      authors={contentfulKnowledge.authors}
      videoUrl={contentfulKnowledge.videoUrl}
      thumbnailImage={contentfulKnowledge.jsonLdThumbnailImage}
      featuredImage={contentfulKnowledge.jsonLdFeaturedImage}
      readingTimeMins={contentfulKnowledge.fields.readingTimeMins}
    />

    <Container maxW="container.lg">
      <Box mt={4}>
        <CanonicalLink color="orange.500" to="/knowledgebase/">
          back for more knowledge
        </CanonicalLink>
      </Box>

      <Box as="header" mt={4} mb={8}>
        <Heading as="h1" mb={2}>
          {contentfulKnowledge.title}
        </Heading>
        <KnowledgeMetaHeader knowledgebaseArticle={contentfulKnowledge} />
      </Box>

      <Html
        source={contentfulKnowledge.fields.contentHtml}
        headerLevelStart={1}
        mb={6}
      />

      {contentfulKnowledge.fields.keywords &&
        contentfulKnowledge.fields.keywords.length > 0 && (
          <Box mt={12} mb={6}>
            <Divider mt={4} mb={4} />
            <HStack as="aside" spacing={2} wrap="wrap">
              {contentfulKnowledge.fields.keywords.map((keyword) => (
                <CanonicalLink
                  key={keyword}
                  to={`/search?query=${encodeURIComponent(
                    '"',
                  )}${keyword}${encodeURIComponent('"')}`}
                >
                  #{keyword}
                </CanonicalLink>
              ))}
            </HStack>
          </Box>
        )}

      {contentfulKnowledge.relatedPages &&
        contentfulKnowledge.relatedPages.length > 0 && (
          <Box as="aside" mt={8}>
            <Heading as="h1" size="xl">
              Further Reading
            </Heading>
            <Text mt={4} mb={4}>
              Like this knowledgebase article or want more information? Why not{' '}
              <CanonicalLink to="/knowledgebase/">
                read more from our Knowledgebase?
              </CanonicalLink>
            </Text>
            <SimpleGrid columns={[1, 2, 4, 4]} rows={[2, 2, 1]} spacing={4}>
              {contentfulKnowledge.relatedPages.map((relatedPage, index) => (
                <Flex key={relatedPage.id} justify="center">
                  <KnowledgebaseRelatedPageCard
                    display={index >= 2 ? ['none', 'none', 'flex'] : 'flex'}
                    page={relatedPage}
                  />
                </Flex>
              ))}
            </SimpleGrid>
          </Box>
        )}
    </Container>

    <Jumbotron as="aside" mt={12} py={8} bg="tt.darkBlue">
      <NewsletterSignup />
    </Jumbotron>
  </PageLayout>
);

export const query = graphql`
  query KnowledgebaseSinglePageBySlug($slug: String!) {
    contentfulKnowledge(slug: { eq: $slug }) {
      id
      title
      slug
      heading
      knowledgeType
      createdAt
      updatedAt
      publishDate
      metaDescription
      videoUrl
      metaDescription
      featuredImage {
        ...FeaturedImage
      }
      jsonLdFeaturedImage: featuredImage {
        ...JsonLdFeaturedImage
      }
      jsonLdThumbnailImage: featuredImage {
        ...JsonLdThumbnailImage
      }
      authors {
        id
        firstName
        lastName
        email
        jobTitle
      }
      relatedPages {
        id
        slug
        title
        featuredImage {
          ...ThumbnailImage
        }
        fields {
          path
          url
          writtenAt
          isNew
          isRecent
          keywords
        }
      }
      fields {
        path
        url
        plaintextContent
        readingTimeMins
        readingTimeText
        writtenAt
        isNew
        isRecent
        keywords
        contentHtml
      }
    }
  }
`;

export default KnowledgebasePage;
