import React from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import { getSrc, getImage } from 'gatsby-plugin-image';

import httpsUrl from 'timelesstime-ui/utils/https-url';
import JsonLd, { organizationId } from 'timelesstime-ui/components/json-ld';

const learningResourceType = (knowledgeType) => {
  if (!knowledgeType) {
    return '';
  }
  return knowledgeType.toLowerCase();
};

export const type = (knowledgeType) => {
  if (!knowledgeType) {
    return '';
  }
  switch (knowledgeType.toLowerCase()) {
    case 'opinion':
      return 'BlogPosting';
    case 'white paper':
      return 'ScholarlyArticle';
    case 'audio visual':
    default:
      return 'Article';
  }
};
export const id = (slug, knowledgeType) =>
  `https://timelesstime.co.uk/knowledgebase/${slug}#${type(knowledgeType)}/`;

const orgId = organizationId();

const KnowledgeStructuredData = ({
  slug,
  knowledgeType,
  description,
  title,
  writtenAt,
  updatedAt,
  plaintextContent,
  authors,
  videoUrl,
  thumbnailImage,
  featuredImage,
  shortMeta,
  readingTimeMins,
}) => {
  const thumbnailImageSrc = getSrc(getImage(thumbnailImage));
  const featuredImageSrc = getSrc(getImage(featuredImage));
  return (
    <JsonLd
      data={{
        '@context': 'http://schema.org',
        '@type': type(knowledgeType),
        '@id': id(slug, knowledgeType),
        mainEntityOfPage: `https://timelesstime.co.uk/knowledgebase/${slug}/`,
        url: `https://timelesstime.co.uk/knowledgebase/${slug}/`,
        description,
        headline: title,
        name: title,
        copyrightYear: format(writtenAt, 'yyyy'),
        datePublished: format(writtenAt, 'yyyy-MM-dd'),
        dateCreated: format(writtenAt, 'yyyy-MM-dd'),
        dateModified: format(updatedAt, 'yyyy-MM-dd'),
        learningResourceType: learningResourceType(knowledgeType),
        timeRequired: `P${readingTimeMins}M`,
        author: authors.map((author) => ({
          '@type': 'Person',
          '@id': `https://timelesstime.co.uk#person/${author.firstName}${author.lastName}`,
          name: `${author.firstName} ${author.lastName}`,
          givenName: author.firstName,
          familyName: author.lastName,
          jobTitle: author.jobTitle,
          email: author.email,
        })),
        audience: {
          '@type': 'BusinessAudience',
          numberOfEmployees: {
            '@type': 'QuantitativeValue',
            minValue: '1',
            maxValue: '250',
          },
        },
        inLanguage: {
          '@type': 'Language',
          name: 'English',
          alternateName: 'en',
        },
        isAccessibleForFree: true,
        creator: {
          '@id': orgId,
        },
        publisher: {
          '@id': orgId,
        },
        copyrightHolder: {
          '@id': orgId,
        },
        provider: {
          '@id': orgId,
        },
        thumbnailUrl: httpsUrl(thumbnailImageSrc),
        image: httpsUrl(featuredImageSrc),
        ...(shortMeta
          ? {}
          : {
              text: plaintextContent,
            }),
        ...(videoUrl
          ? {
              '@type': 'VideoObject',
              description,
              name: title,
              headline: title,
              embedUrl: videoUrl,
              thumbnailUrl: httpsUrl(thumbnailImageSrc),
              image: httpsUrl(featuredImageSrc),
              copyrightYear: format(writtenAt, 'yyyy'),
              datePublished: format(writtenAt, 'yyyy-MM-dd'),
              dateCreated: format(writtenAt, 'yyyy-MM-dd'),
              dateModified: format(updatedAt, 'yyyy-MM-dd'),
              audience: {
                '@type': 'BusinessAudience',
                numberOfEmployees: {
                  '@type': 'QuantitativeValue',
                  minValue: '1',
                  maxValue: '250',
                },
              },
              productionCompany: {
                '@id': orgId,
              },
              author: {
                '@id': orgId,
              },
              creator: {
                '@id': orgId,
              },
              publisher: {
                '@id': orgId,
              },
              copyrightHolder: {
                '@id': orgId,
              },
              provider: {
                '@id': orgId,
              },
              inLanguage: {
                '@type': 'Language',
                name: 'English',
                alternateName: 'en',
              },
              isAccessibleForFree: true,
            }
          : {}),
      }}
    />
  );
};

KnowledgeStructuredData.defaultProps = {
  videoUrl: undefined,
  shortMeta: false,
};

KnowledgeStructuredData.propTypes = {
  slug: PropTypes.string.isRequired,
  knowledgeType: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  writtenAt: PropTypes.instanceOf(Date).isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  createdAt: PropTypes.instanceOf(Date).isRequired,
  updatedAt: PropTypes.instanceOf(Date).isRequired,
  plaintextContent: PropTypes.string.isRequired,
  authors: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      jobTitle: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    }),
  ).isRequired,
  videoUrl: PropTypes.string,
  thumbnailImage: PropTypes.object.isRequired,
  featuredImage: PropTypes.object.isRequired,
  readingTimeMins: PropTypes.number.isRequired,
  shortMeta: PropTypes.bool,
};

export default KnowledgeStructuredData;
