import React from 'react';
import { getSrc, getImage } from 'gatsby-plugin-image';
import { Flex, useToken } from '@chakra-ui/react';
import tinycolor from 'tinycolor2';

import CanonicalLink from 'timelesstime-ui/components/canonical-link';
import Heading from 'timelesstime-ui/components/heading';

const KnowledgebaseRelatedPageCard = ({ page, ...props }) => {
  const blue = useToken('colors', 'tt.darkBlue');
  const featuredImageSrc = getSrc(getImage(page.featuredImage));
  const blueBg = tinycolor(blue).setAlpha(0.85).toRgbString();
  const blueBg2 = tinycolor(blue).setAlpha(0.95).toRgbString();
  return (
    <Flex
      as={CanonicalLink}
      to={page.fields.path}
      alignItems="center"
      justifyItems="center"
      justifyContent="center"
      width="100%"
      height="170px"
      textAlign="center"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      backgroundImage={`
      linear-gradient(to bottom, ${blueBg}, ${blueBg}),
        url(${featuredImageSrc})
        `}
      _hover={{
        backgroundImage: `
        linear-gradient(to bottom, ${blueBg2}, ${blueBg2}),
        url(${featuredImageSrc})
        `,
      }}
      {...props}
    >
      <Heading as="h1" color="white" padding={4} display="block" fontSize="xl">
        {page.title}
      </Heading>
    </Flex>
  );
};

export default KnowledgebaseRelatedPageCard;
